<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingProductionData">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Chargement des données de la production...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-layers mr-2"></i>
              <strong>
                Production :
                <span v-if="productionName">
                  ({{productionName}})
                </span>
              </strong>
              <div class="card-header-actions">
                <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="addModalSpent"
                         @hide="onAddModalSpentClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <c-input container-class="mb-3" label="Libellé de la dépense" placeholder="Ex: Dépense 1"
                               v-model="spentName" :state="spentNameState">
                        Veuillez saisir un nom
                      </c-input>
                      <c-input container-class="mb-3" type="quantity" label="Coût de la dépense" placeholder="Ex: 50000"
                               v-model="spentCost" :unit="exploitationCurrency"
                               step="500" :state="spentCostState">
                        Veuillez spécifier un coût
                      </c-input>
                      <div class="d-inline-flex w-100">
                        
                        <c-input container-class="mb-3" type="select" label="Catégorie de la dépense"
                                 v-model="spentCategory"
                                 :options="listSpentCategories" :state="spentCategoryState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez la catégorie de la dépense --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button"
                                  title="Ajouter une nouvelle catégorie"
                                  @click="addSpentCategory" :disabled="spentCategoryNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer une nouvelle catégorie emetteur"
                               v-model="spentCategoryNew" :state="spentCategoryState" v-if="spentCategoryNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement" v-model="spentDate"
                               :time.sync="spentTime">
                      </c-input>
                    </b-col>
                    <b-col>
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Emetteur de la dépense"
                                 v-model="spentEmitter"
                                 :options="spentsMembersList" :state="spentEmitterState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'emetteur --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addSpentEmitter" :disabled="spentEmitterNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                               v-model="spentEmitterNew" :state="spentEmitterState" v-if="spentEmitterNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Bénéficiaire de la dépense"
                                 v-model="spentReceiver"
                                 :options="spentsMembersList" :state="spentReceiverState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez le bénéficiaire --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addReceiveEmitter" :disabled="spentReceiverNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                               v-model="spentReceiverNew" :state="spentReceiverState" v-if="spentReceiverNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="textarea" label="Description de la dépense"
                               placeholder="Entrez une description..." v-model="spentDescription">
                      </c-input>
                    </b-col>
                    <b-form-file
                      v-model="spentFiles"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                    ></b-form-file>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddSpent"
                                    :fetching=fetchingCreateSpent>
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" @click="addModalSpent = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                <b-button varriant="secondary" :to="dashboardPage" class="mr-2"
                          title="Tableau de bord de votre production" v-b-tooltip.hover
                          v-if="productionStatus.name != 'PLANNED'">
                  <i class="fa fa-line-chart"></i>
                </b-button>
                <b-button variant="primary" @click="newSpent">
                  <i class="fa fa-plus"></i>
                  Enregistrer une dépense
                </b-button>
              </div>
            </div>
            <b-row>
              <b-modal :title="addModalTitle" class="modal-primary" v-model="addNodeModal" @hide="onAddModalClose">
                <b-form>
                  <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                  
                  <b-input-group v-if="toAdd.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in toAdd">
                      {{item.name}}
                      <b-link @click.prevent="removeItem(item)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center" v-else>{{noItemText}}</p>
                  
                  <b-input-group class="mb-3 mt-3">
                    <b-autocomplete-input :placeholder="searchPlaceholder" v-model="search"
                                          :data="searchAutocompleteData" class="autocomplete"
                                          @hit="submitSearch($event)" ref="searchAutocompleteInput"
                                          :serializer="item => item.name"
                                          :minMatchingChars=0>
                      <template slot="append">
                        <b-button variant="primary" @click.prevent="submitSearch()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                </b-form>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAdd" :fetching=false>
                    Valider
                  </button-spinner>
                  <b-button variant="secondary" @click="addNodeModal = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
              
              <div class="d-inline-flex w-100">
                <c-input container-class="mb-3 mr-2" type="select" label=""
                         v-model="parcelModeSelected"
                         :options="listParcelModes" :state="productionParcelModeState">
                  <template slot="first">
                    <option :value="null" disabled>-- Selectionnez le mode de la parcelle --</option>
                  </template>
                  Veuillez sélectionner une parcelle
                </c-input>
                <info-message class="pt-2">
                  Une production est le démarrage d'un cycle de croissance d'une culture.
                  Nous présentons la liste de toutes les productions de votre exploitation
                </info-message>
                <c-input container-class="mb-3 w-100" type="select" label=""
                         v-model="parcelSelected"
                         :options="listParcels" id="parcel-list" :disabled="fieldDisabled"
                         :state="productionParcelState">
                  <template slot="first">
                    <option :value="null" disabled>-- Selectionnez votre parcelle --</option>
                  </template>
                  Veuillez sélectionner une parcelle
                </c-input>
              </div>
              <b-col class="b-r-1">
                <tree-view
                  :data="treeData"
                  :contextMenuItems=ctxMenuItems
                  :showIcons=true
                  :renameNodeOnDblClick=false
                  @nodeSelect="onNodeSelect"
                  @contextMenuItemSelect="onCtxMenuSelect"
                  nodeLabelProp=text
                  prependIconClass=fa
                  defaultIconClass="fa-cog">
                </tree-view>
              </b-col>
              <b-col v-if="cultureSelected">
                <h5>{{selectedNodeData.text}}</h5>
              </b-col>
              <b-col v-else-if="cultureStepSelected">
                <h5>{{selectedNodeData.text}}</h5>
                
                <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                    {{selectedNodeData.status.name}}
                  </b-badge>
                </b-input-group>
                
                <div class="w-100 text-center">
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEndStep" :fetching=fetchingSaveAndLaunch>
                    Clôturer l'étape
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4" @click=onStartStep
                                  :fetching=fetchingStartStep>
                    Démarrer l'étape
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else-if="activitySelected">
                <h5>{{selectedNodeData.text}}</h5>
                <p>
                  Surface considérée :
                  <default-unit>{{surfaceParcel}} ha</default-unit>
                </p>
                <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                    {{selectedNodeData.status.name}}
                  </b-badge>
                </b-input-group>
                
                <text-recommended>La durée recommandée est de : {{activityRecommendationDuration}} jours
                </text-recommended>
                <c-input container-class="mb-3" type="quantity" label="Durée de l'activité" placeholder="Ex: 12"
                         v-model="activityDuration" unit="Jour(s)" :state="activityDurationState"
                         :disabled="activityStatusDisabled">
                  Veuillez spécifier une durée
                </c-input>
                
                <text-recommended>
                  Le prix recommandé est : {{activityRecommendationCost +' '+ exploitationCurrency +' / pers. / jour'}}
                </text-recommended>
                <c-input container-class="mb-3" type="quantity" label="Coût de l'activité" placeholder="Ex: 50000"
                         v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'"
                         :state="activityCostState" :disabled="activityStatusDisabled">
                  Veuillez spécifier un coût
                </c-input>
                
                <text-recommended>Le nombre de personnes recommandé est de : {{activityRecommendationPersonNumber}}
                  personnes
                </text-recommended>
                <b-input-group v-if="activityStaff.length">
                  <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in activityStaff">
                    {{item.name}}
                    <b-link @click.prevent="removeStaff(item)">
                      <i class="fa fa-remove text-danger"></i>
                    </b-link>
                  </b-badge>
                </b-input-group>
                <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>
                
                <b-input-group class="mb-3">
                  <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                        :data="staffAutocompleteData" class="autocomplete"
                                        @hit="addStaff($event)" ref="staffAutocompleteInput"
                                        :serializer="item => item.name"
                                        :minMatchingChars=0 :disabled="activityStatusDisabled">
                    <template slot="append">
                      <b-button variant="primary" @click.prevent="addStaff()">
                        Ajouter
                      </b-button>
                    </template>
                  </b-autocomplete-input>
                </b-input-group>
                <template v-if="activityInputs.length != 0">
                  <label class="text-muted font-weight-bold">Intrants</label>
                  <b-input-group class="mb-3" v-for="(input, i) in activityInputs">
                    <text-recommended>
                      Recommandation : {{activityRecommendationInputs[i].quantity+' ' + input.unit+' '+input.inputName}}
                    </text-recommended>
                    <b-input-group-prepend class="w-75">
                      <b-input-group-text class="w-100">{{input.inputName}}</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="number" class="form-control" placeholder="Qté"
                                  v-model="input.quantity" :state="activityInputQuantityState[i]"
                                  :aria-describedby="`activity-input-quantity-${i}-feedback`" min="0" step="1"
                                  :disabled="activityStatusDisabled" />
                    <b-input-group-append class="unit">
                      <b-input-group-text class="w-100">{{input.unit}}</b-input-group-text>
                    </b-input-group-append>
                    <b-form-invalid-feedback :id="`activity-input-quantity-${i}-feedback`">
                      Veuillez entrer une quantité
                    </b-form-invalid-feedback>
                  </b-input-group>
                </template>
                
                <template v-if="activityEquipments.length !=0">
                  <label class="text-muted font-weight-bold">Equipements</label>
                  <b-input-group class="mb-3" v-for="(equipment, i) in activityEquipments">
                    <text-recommended>
                      Recommandation : {{activityRecommendationEquipments[i].quantity+' '+equipment.equipmentName}}
                    </text-recommended>
                    <b-input-group-prepend class="w-75">
                      <b-input-group-text class="w-100">{{equipment.equipmentName}}</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input type="number" class="form-control" placeholder="Qté"
                                  v-model="equipment.quantity" :state="activityEquipmentQuantityState[i]"
                                  :aria-describedby="`activity-equipment-quantity-${i}-feedback`" min="0" step="1"
                                  :disabled="activityStatusDisabled" />
                    <b-input-group-append class="unit">
                      <b-input-group-text class="w-100">{{equipment.unit}}</b-input-group-text>
                    </b-input-group-append>
                    <b-form-invalid-feedback :id="`activity-equipment-quantity-${i}-feedback`">
                      Veuillez entrer une quantité
                    </b-form-invalid-feedback>
                  </b-input-group>
                </template>
                
                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onUpdateActivity"
                                  :fetching=false>
                    Enregistrer
                  </button-spinner>
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEndActivity" :fetching=fetchingStartActivity>
                    Clôturer l'activité
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click="onStartActivity"
                                  :fetching=fetchingStartActivity>
                    Démarrer l'activité
                  </button-spinner>
                </div>
              </b-col>
              <b-col v-else>
                <h5>Production</h5>
                
                <b-input-group class="mb-3 mt-3" v-if="productionStatus">
                  <b-form-text>Statut</b-form-text>
                  <b-badge class="font-sm ml-2" :variant="productionStatus.variant">
                    {{productionStatus.name}}
                  </b-badge>
                </b-input-group>
                
                <c-input container-class="mb-3 mt-3" label="Nom de la production" placeholder="Ex: Cacao 2019"
                         v-model="productionName" :state="productionNameState">
                  Veuillez saisir un nom
                </c-input>
                
                <c-input container-class="mb-3" type="textarea" label="Description"
                         placeholder="Entrez une description..." v-model="productionDescription">
                </c-input>
                
                <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="productionStartDay"
                         :time.sync="productionStartTime" :disabled="fieldDisabled"
                         :state="productionStartDateTimeState">
                  Veuillez selectionner une durée
                </c-input>
                
                <div class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSave" :fetching=fetchingSave>
                    Enregistrer
                  </button-spinner>
                  <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                  @click="onEnd" :fetching=fetchingSaveAndLaunch>
                    Clôturer la production
                  </button-spinner>
                  <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click="onSaveAndLaunch"
                                  :fetching=fetchingSaveAndLaunch>
                    Lancer la production
                  </button-spinner>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  
  import TreeView from "../../../components/tree-component/components/TreeView"
  import {Api, Toast, price, Regex} from "../../../helpers/index"
  
  class TreeNode {
    children = []
    parent = null
    
    constructor(props) {
      this.fill(props)
    }
    
    fill(props) {
      for (let key in props) {
        if (props.hasOwnProperty(key))
          this[key] = props[key]
      }
    }
    
    toJSON() {
      const obj = {}
      for (let key in this) {
        if (this.hasOwnProperty(key) && key !== "children")
          obj[key] = this[key]
      }
      // if (this.children && this.children.length)
      //   obj.children = this.children.map(child => child.toJSON())
      
      return obj
    }
  }
  
  export default {
    name: "ExploitationProduction",
    title: "PIA - Production",
    components: {TreeView},
    data() {
      return {
        parcelData: null,
        fetchingParcelData: false,
        
        parcelSelected: null,
        parcelModeSelected: null,
        fetchingParcelList: false,
        parcels: [],
        
        productionFound: false,
        fetchingProductionData: false,
        error: null,
        creating: false,
        
        productionStatus: null,
        productionName: '',
        productionDescription: '',
        productionStartDay: '',
        productionStartTime: '',
        fetchingSave: false,
        fetchingSaveAndLaunch: false,
        
        spentName: '',
        spentCost: '',
        
        
        spentCategory: null,
        spentCategoryNew: "",
        spentCategoryNewDisplay: false,
        
        spentDate: "",
        spentTime: "",
        spentEmitter: null,
        spentReceiver: null,
        spentDescription: '',
        fetchingCreateSpent: false,
        submittedSpent: false,
        spentFiles: [],
        spentEmitterNew: "",
        spentReceiverNew: "",
        spentsMembers: [],
        spentEmitterNewDisplay: false,
        spentReceiverNewDisplay: false,
        spentCategories: [],
        
        activityDuration: '',
        activityCost: '',
        activityStaff: [],
        staffSearch: '',
        activityInputs: [],
        activityEquipments: [],
        // il permet de verifier que la production n'est pas en cours pour pouvoir ainsi disable les champs de modification
        activityStatusDisabled: false,
        fetchingStartActivity: false,
        
        fetchingStartStep: false,
        
        addModalSpent: false,
        
        addNodeModal: false,
        toAdd: [],
        search: '',
        
        cultures: [],
        fetchingCultureList: null,
        cultureSteps: [],
        fetchingStepList: null,
        activities: [],
        fetchingActivityList: null,
        staff: [],
        fetchingStaffList: null,
        
        nodeTypes: {
          Production: {
            icon: "fa-cog",
            ctxMenuItems: [{code: "ADD_CHILD", label: "Ajouter des cultures"}],
            children: ["Culture"],
            modal: {
              noItemText: "Aucune culture sélectionnée.",
              searchPlaceholder: "Ajouter une culture...",
              autocompleteData: "cultures"
            }
          },
          Culture: {
            icon: "fa-leaf",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des étapes de culture"},
              {code: "DEL", label: "Supprimer la culture"}
            ],
            children: ["CultureStep"],
            modal: {
              noItemText: "Aucune étape sélectionnée.",
              searchPlaceholder: "Ajouter une étape...",
              autocompleteData: "cultureSteps"
            },
            refKey: "cultureId"
          },
          CultureStep: {
            icon: "fa-list-ol",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des activités"},
              {code: "DEL", label: "Supprimer l'étape de culture"}
            ],
            children: ["Activity"],
            modal: {
              noItemText: "Aucune activité sélectionnée.",
              searchPlaceholder: "Ajouter une activité...",
              autocompleteData: "activities"
            },
            refKey: "cultureStepId"
          }
          ,
          Activity: {
            icon: "fa-gavel",
            ctxMenuItems: [
              {code: "DEL", label: "Supprimer l'activité"}
            ],
            children: [],
            refKey: "activityId",
            properties: [
              {key: "duration", defaultValue: ''},
              {key: "cost", defaultValue: ''},
              {key: "staff", defaultValue: () => []},
              {
                key: "inputs",
                defaultValue: model => model.inputs.map(el => ({
                  inputId: el.id,
                  inputName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              },
              {
                key: "equipments",
                defaultValue: model => model.equipments.map(el => ({
                  equipmentId: el.id,
                  equipmentName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              }
            ]
          }
        },
        productionData: new TreeNode({
          id: 0, text: "Production", type: "Production"
        }),
        ctxMenuItems: [],
        selectedNode: null,
        submittedProduction: false,
        submittedActivity: false,
        
        activityRecommendationDuration: null,
        activityRecommendationCost: null,
        activityRecommendationPersonNumber: null,
        activityRecommendationInputs: [],
        activityRecommendationEquipments: [],
        defaultsParameters: [],
        
        
        listParcelModes: [
          {
            text: 'mono-prod',
            value: 'MONO'
          },
          {
            text: 'multi-prod',
            value: 'MULTI'
          },
        ]
      }
    },
    created() {
      this.fetchingParcelList = true
      Api.get('/exploitation/parcel/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.parcels = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingParcelList = false
        })
      this.fetchingCultureList = true
      Api.get('/exploitation/culture/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.cultures = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingCultureList = false
        })
      
      this.fetchingStepList = true
      Api.get('/exploitation/culture/step/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.cultureSteps = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStepList = false
        })
      
      this.fetchingActivityList = true
      Api.get('/exploitation/activity/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.activities = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingActivityList = false
        })
      
      this.fetchingStaffList = true
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staff = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
      this.fetchingProductionData = true
      Api.get('/exploitation/production', {
        exploitationId: this.exploitationId,
        productionId: this.productionId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const production = res.data.data
            this.setProductionData(production)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingProductionData = false
        })
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/spent-category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentCategories = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
    },
    mounted() {
    
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      listParcels() {
        return this.parcels.map(item => ({
          value: item.id,
          text: item.name
        }))
      },
      parcelId() {
        return this.parcelSelected
      },
      productionId() {
        return this.$route.params.prodId
      },
      dashboardPage() {
        return '/exploitation/' + this.exploitationId + '/production/' + this.productionId + '/dashboard'
      },
      hasError() {
        return !!this.error
      },
      errorMessage() {
        return this.error ? this.error.message : null
      },
      treeData() {
        const productionData = this.productionData
        if (this.productionName)
          productionData.text = this.productionName
        else
          productionData.text = 'Production ' + new Date().toDateString()
        
        return [productionData]
      },
      
      canCreate() {
        return !(this.productionFound || this.fetchingProductionData || this.creating)
      },
      
      cultureSelected() {
        return this.selectedNode && this.selectedNode.data.type === "Culture"
      },
      cultureStepSelected() {
        return this.selectedNode && this.selectedNode.data.type === "CultureStep"
      },
      activitySelected() {
        return this.selectedNode && this.selectedNode.data.type === "Activity"
      },
      selectedNodeStarted() {
        if (this.selectedNode && this.selectedNode.data.type !== "Production")
          return this.selectedNode.data.status && this.selectedNode.data.status.name == 'RUNNING'
        
        return this.productionStatus && this.productionStatus.name == 'RUNNING'
      },
      selectedNodeData() {
        return this.selectedNode && this.selectedNode.data ? this.selectedNode.data : {}
      },
      
      productionStartDate() {
        return this.productionStartDay + ' ' + this.productionStartTime
      },
      
      productionNameState() {
        return !this.submittedProduction || this.productionName.trim().length >= 3 ? null : false
      },
      productionStartDateTimeState() {
        return !this.submittedProduction || (this.productionStartDay != "" && this.productionStartTime != "") ? null : false
      },
      activityDurationState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityDuration) ? null : false
      },
      activityCostState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityCost) ? null : false
      },
      activityInputQuantityState() {
        return !this.submittedActivity ? [null] : this.activityInputs.map(input => {
          return Regex.isPositiveNumber(input.quantity) ? null : false
        })
      },
      activityEquipmentQuantityState() {
        return !this.submittedActivity ? [null] : this.activityEquipments.map(equip => {
          return Regex.isPositiveNumber(equip.quantity) ? null : false
        })
      },
      
      staffAutocompleteData() {
        return this.staff
      },
      
      // Modal
      addModalTitle() {
        if (!this.selectedNode)
          return ''
        
        return this.nodeTypes[this.selectedNode.data.type].ctxMenuItems[0].label
      },
      noItemText() {
        if (!this.selectedNode)
          return ''
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.noItemText : null
      },
      searchPlaceholder() {
        if (!this.selectedNode)
          return ''
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.searchPlaceholder : null
      },
      searchAutocompleteData() {
        if (!this.selectedNode)
          return []
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? this[modal.autocompleteData] : []
      },
      exploitationCurrency() {
        return price()
      },
      surfaceParcel() {
        return this.parcelSelected ? this.parcels.find(item => item.id == this.parcelSelected).area : 1
      },
      fieldDisabled() {
        return this.productionStatus.name != 'PLANNED'
      },
      spentNameState() {
        return !this.submittedSpent || this.spentName.trim().length >= 3 ? null : false
      },
      spentCostState() {
        return !this.submittedSpent || Regex.isPositiveNumber(this.spentCost) ? null : false
      },
      spentCategoryState() {
        return !this.submittedSpent || (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3) ? null : false
      },
      spentEmitterState() {
        return !this.submittedSpent || (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3) ? null : false
      },
      spentReceiverState() {
        return !this.submittedSpent || (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3) ? null : false
      },
      listSpentCategories() {
        return this.spentCategories.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      spentsMembersList() {
        return this.spentsMembers.map(member => ({
          text: member.name,
          value: member
        }))
      }
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      },
    },
    methods: {
      newProduction() {
        this.creating = true
      },
      
      onCtxMenuSelect({code}, node) {
        const args = code.split('-')
        switch (args[0]) {
          case "ADD_CHILD":
            this.addNodeModal = true
            break
          case "DEL":
            this.deleteNode(node.data.id)
            break
        }
        return true
      },
      onNodeSelect(node, selected) {
        console.log('this is the ', node.data.type)
        if (selected) {
          this.selectedNode = node
          this.ctxMenuItems = this.nodeTypes[node.data.type].ctxMenuItems
          
          if (node.data.type === "Activity") {
            this.submittedActivity = false
            this.activityDuration = node.data.duration
            this.activityCost = node.data.cost
            this.activityStaff = node.data.staff ? node.data.staff : []
            this.activityInputs = node.data.inputs
            this.activityEquipments = node.data.equipments
            this.activityStatusDisabled = node.data.status == 'RUNNING'
            
            
            // we begin with recommandations
            this.defaultsParameters = {
              duration: node.data.duration,
              cost: this.activityCost,
              personNumber: node.data.personNumber,
              inputs: node.data.inputs,
              equipments: this.activityEquipments
            }
            const valuePersonNumber = Math.ceil(this.surfaceParcel / 10)
            if (valuePersonNumber <= 1 || valuePersonNumber <= this.defaultsParameters.personNumber) {
              this.activityRecommendationPersonNumber = this.defaultsParameters.personNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            } else {
              this.activityRecommendationPersonNumber = valuePersonNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            }
            this.activityRecommendationCost = this.activityCost
            this.activityRecommendationInputs = this.activityInputs.map(item => ({
              ...item,
              quantity: this.multiplyWithSurface(item.quantity)
            }))
            this.activityRecommendationEquipments = this.activityEquipments.map(item => ({
              ...item
            }))
          }
          else {
            this.activityDuration = ''
            this.activityCost = ''
            this.activityStaff = []
            this.activityInputs = []
            this.activityEquipments = []
          }
        }
      },
      deleteNode(id, root = this.productionData) {
        if (root.children) {
          const childrenLength = root.children.length
          root.children = root.children.filter(el => el.id !== id)
          if (childrenLength === root.children.length)
            root.children.forEach(node => this.deleteNode(id, node))
        }
      },
      findNode(id, root = this.productionData) {
        if (root.id === id)
          return root
        if (root.children) {
          let node
          for (let i = 0; i < root.children.length; i++) {
            node = this.findNode(id, root.children[i])
            if (node)
              return node
          }
        }
        return null
      },
      
      getProductionData() {
        return this.productionData.children.map(culture => ({
          cultureId: culture.cultureId,
          cultureSteps: culture.children.map(cultureStep => ({
            cultureStepId: cultureStep.cultureStepId,
            activities: cultureStep.children
          }))
        }))
      },
      setProductionData(data = {}) {
        this.parcelSelected = data.parcel.id
        this.productionStatus = data.status
        this.productionName = data.name
        this.productionDescription = data.description
        this.parcelModeSelected = data.takeManyProduction ? 'MULTI' : (data.takeManyProduction == false ? 'MONO' : null)
        if (data.startDate) {
          const dateParts = data.startDate.split(' ')
          if (dateParts.length)
            this.productionStartDay = dateParts[0]
          if (dateParts.length >= 2)
            this.productionStartTime = dateParts[1].toString()
        }
        this.productionData = new TreeNode({
          id: data.id,
          text: data.name,
          type: "Production",
          actions: [
            {
              icon: 'fa-plus',
              text: 'Ajouter culture'
            }],
          ctxMenuItems: [{code: "ADD_CHILD", label: "Ajouter des cultures"}],
          children: data.cultures.map(culture => {
            const cultureNode = new TreeNode()
            cultureNode.fill({
              ...culture,
              id: "Culture-" + culture.id,
              icon: this.nodeTypes.Culture.icon,
              type: "Culture",
              text: culture.name,
              cultureId: culture.id,
              parent: this.productionData,
              ctxMenuItems: [
                {code: "ADD_CHILD", label: "Ajouter des étapes"},
                {code: "DEL", label: "Supprimer la culture"}
              ],
              actions: [
                {
                  icon: 'fa-plus',
                  text: 'Ajouter étape'
                }, {
                  icon: 'fa-minus',
                  text: 'Supprimer culture',
                }
              ],
              children: culture.cultureSteps.map(cultureStep => {
                const stepNode = new TreeNode()
                stepNode.fill({
                  ...cultureStep,
                  id: "CultureStep-" + cultureStep.id,
                  icon: this.nodeTypes.CultureStep.icon,
                  type: "CultureStep",
                  text: cultureStep.name,
                  cultureStepId: cultureStep.id,
                  status: cultureStep.status,
                  activities: undefined,
                  parent: cultureNode,
                  actions: [
                    {
                      icon: 'fa-plus',
                      text: 'Ajouter act',
                    }, {
                      icon: 'fa-minus',
                      text: 'Supprimer étape',
                    }
                  ],
                  ctxMenuItems: [
                    {code: "ADD_CHILD", label: "Ajouter des activités"},
                    {code: "DEL", label: "Supprimer l'étape de culture"}
                  ],
                  children: cultureStep.activities.map(activity => new TreeNode({
                    ...activity,
                    id: "Activity-" + activity.id,
                    icon: this.nodeTypes.Activity.icon,
                    type: "Activity",
                    text: activity.name,
                    activityId: activity.id,
                    parent: stepNode,
                    status: activity.status,
                    personNumber: activity.personNumber,
                    children: [],
                    staff: activity.staff,
                    inputs: activity.inputs.map(input => ({
                      ...input,
                      inputId: input.id,
                      inputName: input.name
                    })),
                    equipments: activity.equipments.map(equipment => ({
                      ...equipment,
                      equipmentId: equipment.id,
                      equipmentName: equipment.name
                    })),
                    actions: [{
                      icon: 'fa-minus',
                      text: 'Supprimer act',
                    }],
                    ctxMenuItems: [
                      {code: "DEL", label: "Supprimer l'activité"}
                    ],
                    //to verifiy if it's an activity to display the real color in the TreeNode component
                    isActivity: true
                  }))
                })
                return stepNode
              })
            })
            return cultureNode
          })
        })
        
        
      },
      onSubmit(route, fetchingAttribute, callback, data = {}) {
        this[fetchingAttribute] = true
        Api.post(route, {
          exploitationId: this.exploitationId,
          parcelId: this.parcelId,
          productionId: this.productionId,
          startDate: this.productionStartDate,
          name: this.productionName,
          description: this.productionDescription,
          cultures: this.getProductionData(),
          ...data,
  
          // to save the takenManyProd parameter
          takeManyProduction: this.parcelModeSelected && this.parcelModeSelected == 'MULTI',
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              callback(res.data.data)
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
            this.error = error
          })
          .then(() => {
            this[fetchingAttribute] = false
          })
      },
      onSave() {
        this.submittedProduction = true
        if (!this.validProduction()) return
        this.onSubmit('/exploitation/production/save', "fetchingSave", data => {
          this.productionStatus = data.status
          Toast.success('Production enregistrée avec succès !')
          this.submittedProduction = false
        })
      },
      async onSaveAndLaunch() {
        this.submittedProduction = true
        if (!this.validProduction()) return
  
        const res = await Api.post('/exploitation/production/parcel-status', {
          parcelId: this.parcelSelected
        })
  
        if (res.data.status === 'success' && res.data.data) {
          if (!res.data.data.canChoose) {
            Toast.error('La parcelle que vous avez choisie est en mode mono-prod. Il est cependant impossible d\'y lancer une nouvelle production ')
            return
          }
        }else {
          this.error = res.data.error
          return
        }
  
        this.onSubmit(`/exploitation/production/save-start`, "fetchingSaveAndLaunch", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Production démarrée avec succès !')
            this.submittedProduction = false
          }
        })
      },
      onEnd() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer la production "${this.productionName}" ?`))
          return
        
        this.onSubmit(`/exploitation/production/close`, "fetchingSaveAndLaunch", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Production clôturée avec succès !')
          }
        })
      },
      onUpdateActivity(noToast) {
        this.submittedActivity = true
        if (!this.validActivity()) return
        this.selectedNode.data.duration = this.activityDuration
        this.selectedNode.data.cost = this.activityCost
        this.selectedNode.data.staff = this.activityStaff
        this.selectedNode.data.inputs = this.activityInputs
        this.selectedNode.data.equipments = this.activityEquipments
        if (!noToast)
          Toast.success("Modification enregistrées avec succès !")
      },
      onStartActivity() {
        this.onUpdateActivity(true)
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureStepId: node.parent.cultureStepId,
          cultureId: node.parent.parent.cultureId
        }
        this.onSubmit("/exploitation/production/activity/start", "fetchingStartActivity", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Activité démarrée avec succès !')
            this.submittedActivity = false
          }
        }, data)
      },
      onEndActivity() {
        this.onUpdateActivity(true)
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'activité "${this.selectedNode.data.text}" ?`))
          return
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureStepId: node.parent.cultureStepId,
          cultureId: node.parent.parent.cultureId
        }
        this.onSubmit("/exploitation/production/activity/close", "fetchingStartActivity", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Activité clôturée avec succès !')
            this.submittedActivity = false
          }
        }, data)
      },
      
      onStartStep() {
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureStepId: node.cultureStepId,
          cultureId: node.parent.cultureId
        }
        this.onSubmit(`/exploitation/production/culture-step/start`, "fetchingStartStep", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Etape de culture démarrée avec succès !')
          }
        }, data)
      },
      
      onEndStep() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'étape "${this.selectedNode.data.text}" ?`))
          return
        
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureStepId: node.cultureStepId,
          cultureId: node.parent.cultureId
        }
        this.onSubmit(`/exploitation/production/culture-step/close`, "fetchingStartStep", data => {
          if (data) {
            this.setProductionData(data)
            Toast.success('Etape de culture clôturée avec succès !')
          }
        }, data)
      },
      
      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      },
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )
        
        if (item) {
          if (!this.activityStaff.includes(item))
            this.activityStaff.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearStaffAutocompleteInput()
      },
      removeStaff(item) {
        this.activityStaff = this.activityStaff.filter(el => el.id !== item.id || el.user !== item.user)
      },
      
      // Modal
      clearSearchAutocompleteInput() {
        this.$refs.searchAutocompleteInput.inputValue = ''
        this.search = ''
      },
      onAddModalClose() {
        this.toAdd = []
        this.clearSearchAutocompleteInput()
      },
      removeItem(item) {
        this.toAdd = this.toAdd.filter(el => el.id !== item.id)
      },
      submitSearch(item) {
        if (!item && this.search.trim() !== '')
          item = this.searchAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.search.toLowerCase())
          )
        
        if (item) {
          if (!this.toAdd.includes(item))
            this.toAdd.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearSearchAutocompleteInput()
      },
      onSubmitAdd() {
        if (!this.toAdd.length)
          return
        
        const parentType = this.nodeTypes[this.selectedNode.data.type]
        const type = this.nodeTypes[parentType.children[0]]
        if (this.selectedNode.data.type == "Production") {
          this.toAdd.forEach(item => {
            
            const node = new TreeNode({
              id: parentType.children[0] + '-' + item.id,
              icon: type.icon,
              text: item.name,
              type: parentType.children[0],
              parent: this.selectedNode.data.ref,
              ctxMenuItems: [
                {code: "ADD_CHILD", label: "Ajouter des étapes"},
                {code: "DEL", label: "Supprimer la culture"}
              ],
              actions: [
                {
                  icon: 'fa-plus',
                  text: 'Ajouter étape'
                }, {
                  icon: 'fa-minus',
                  text: 'Supprimer culture',
                }
              ]
            })
            if (type.refKey)
              node[type.refKey] = item.id
            
            if (type.properties)
              type.properties.forEach(
                prop => node[prop.key] = typeof prop.defaultValue === "function"
                  ? prop.defaultValue(item)
                  : prop.defaultValue
              )
            Api.get('/exploitation/culture', {
              exploitationId: this.exploitationId,
              cultureId: item.id
            })
              .then(res => {
                if (res.data.status === 'success' && res.data.data) {
                  const currentCulture = res.data.data
                  node.children = currentCulture.cultureSteps.map(cultureStep => {
                    const stepNode = new TreeNode()
                    stepNode.fill({
                      ...cultureStep,
                      id: "CultureStep-" + cultureStep.id,
                      icon: this.nodeTypes.CultureStep.icon,
                      type: "CultureStep",
                      text: cultureStep.name,
                      cultureStepId: cultureStep.id,
                      activities: undefined,
                      parent: currentCulture,
                      actions: [
                        {
                          icon: 'fa-plus',
                          text: 'Ajouter act',
                        }, {
                          icon: 'fa-minus',
                          text: 'Supprimer étape',
                        }
                      ],
                      ctxMenuItems: [
                        {code: "ADD_CHILD", label: "Ajouter des activités"},
                        {code: "DEL", label: "Supprimer l'étape de culture"}
                      ],
                      children: cultureStep.activities.map(activity => {
                        const activityNode = new TreeNode()
                        activityNode.fill({
                          ...activity,
                          id: "Activity-" + activity.id,
                          icon: this.nodeTypes.Activity.icon,
                          type: "Activity",
                          text: activity.name,
                          activityId: activity.id,
                          parent: stepNode,
                          children: [],
                          staff: [],
                          inputs: activity.inputs.map(input => ({
                            ...input,
                            inputId: input.id,
                            inputName: input.name
                          })),
                          equipments: activity.equipments.map(equipment => ({
                            ...equipment,
                            equipmentId: equipment.id,
                            equipmentName: equipment.name
                          })),
                          actions: [{
                            icon: 'fa-minus',
                            text: 'Supprimer act',
                          }],
                          ctxMenuItems: [
                            {code: "DEL", label: "Supprimer l'activité"}
                          ],
                          //to verifiy if it's an activity to display the real color in the TreeNode component
                          isActivity: true
                        })
                        return activityNode
                      })
                    })
                    return stepNode
                  })
                }
                else {
                  this.error = res.data.error
                }
              })
              .catch(error => {
                this.error = {
                  message: 'Echec de la connexion au serveur'
                }
              })
              .then(() => {
                this.fetchingActivityList = false
              })
            
            this.selectedNode.data.children.push(node)
          })
        }
        else if (this.selectedNode.data.type == "Culture") {
          this.toAdd.forEach(item => {
            
            const node = new TreeNode({
              id: parentType.children[0] + '-' + item.id,
              icon: type.icon,
              text: item.name,
              type: parentType.children[0],
              parent: this.selectedNode.data.ref,
              actions: [
                {
                  icon: 'fa-plus',
                  text: 'Ajouter act',
                }, {
                  icon: 'fa-minus',
                  text: 'Supprimer étape',
                }
              ],
              ctxMenuItems: [
                {code: "ADD_CHILD", label: "Ajouter des activités"},
                {code: "DEL", label: "Supprimer l'étape de culture"}
              ],
            })
            if (type.refKey)
              node[type.refKey] = item.id
            
            if (type.properties)
              type.properties.forEach(
                prop => node[prop.key] = typeof prop.defaultValue === "function"
                  ? prop.defaultValue(item)
                  : prop.defaultValue
              )
            Api.get('/exploitation/culture/step', {
              exploitationId: this.exploitationId,
              cultureStepId: item.id
            })
              .then(res => {
                if (res.data.status === 'success' && res.data.data) {
                  node.children = res.data.data.activities.map(cultureStepActivity => {
                    const activityNode = new TreeNode()
                    activityNode.fill({
                      ...cultureStepActivity,
                      id: "Activity-" + cultureStepActivity.id,
                      icon: this.nodeTypes.Activity.icon,
                      type: "Activity",
                      text: cultureStepActivity.name,
                      activityId: cultureStepActivity.id,
                      parent: node,
                      children: [],
                      staff: [],
                      inputs: cultureStepActivity.inputs.map(input => ({
                        ...input,
                        inputId: input.id,
                        inputName: input.name
                      })),
                      equipments: cultureStepActivity.equipments.map(equipment => ({
                        ...equipment,
                        equipmentId: equipment.id,
                        equipmentName: equipment.name
                      })),
                      actions: [{
                        icon: 'fa-minus',
                        text: 'Supprimer act',
                      }],
                      ctxMenuItems: [
                        {code: "DEL", label: "Supprimer l'activité"}
                      ],
                      //to verifiy if it's an activity to display the real color in the TreeNode component
                      isActivity: true
                    })
                    return activityNode
                  })
                }
                else {
                  this.error = res.data.error
                }
              })
              .catch(error => {
                this.error = {
                  message: 'Echec de la connexion au serveur'
                }
              })
              .then(() => {
                this.fetchingActivityList = false
              })
            
            this.selectedNode.data.children.push(node)
          })
        }
        else {
          this.toAdd.forEach(item => {
            
            const node = new TreeNode({
              id: parentType.children[0] + '-' + item.id,
              icon: type.icon,
              text: item.name,
              type: parentType.children[0],
              parent: this.selectedNode.data.ref,
              actions: [{
                icon: 'fa-minus',
                text: 'Supprimer act',
              }],
              ctxMenuItems: [
                {code: "DEL", label: "Supprimer l'activité"}
              ],
              //to verifiy if it's an activity to display the real color in the TreeNode component
              isActivity: true
            })
            if (type.refKey)
              node[type.refKey] = item.id
            
            if (type.properties)
              type.properties.forEach(
                prop => node[prop.key] = typeof prop.defaultValue === "function"
                  ? prop.defaultValue(item)
                  : prop.defaultValue
              )
            this.selectedNode.data.children.push(node)
          })
        }
        this.selectedNode.expand()
        this.addNodeModal = false
      },
      onAddModalSpentClose() {
        this.spentName = ''
        this.spentCost = ''
        this.spentDescription = ''
        this.submittedSpent = false
        this.spentDate = ""
        this.spentTime = ""
        this.spentReceiver = null
        this.spentReceiverNew = ""
        this.spentReceiverNewDisplay = false
        this.spentEmitter = null
        this.spentEmitterNew = ""
        this.spentEmitterNewDisplay = false
        this.spentReceiverNewDisplay = false
        this.spentCategoryNew = ""
        this.spentCategoryNewDisplay = false
      },
      newSpent() {
        this.addModalSpent = true
      },
      valid() {
        return this.spentName.trim().length >= 3
          && (this.spentCost || Regex.isPositiveNumber(this.spentCost))
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          var reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = (ev => {
              resolve(ev.target.result)
            })
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddSpent() {
        this.submittedSpent = true
        if (!this.validSpent())
          return
        
        this.fetchingCreateSpent = true
        
        const base64Files = await this.getFiles(this.spentFiles)
        
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.spentEmitter ? true : false,
          user: this.spentEmitter || this.spentEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.spentReceiver ? true : false,
          user: this.spentReceiver || this.spentReceiverNew
        }
        
        // retrieving the old|new mode
        const cat = {
          isExist: this.spentCategory ? true : false,
          category: this.spentCategory || this.spentCategoryNew
        }
        
        Api.post('/exploitation/production/spent/create', {
          exploitationId: this.exploitationId,
          productionId: this.productionId,
          name: this.spentName,
          amount: this.spentCost,
          spentEmitter: emitter,
          spentReceiver: receiver,
          spentCategory: cat,
          spentCategoryId: 0,
          spentDate: this.spentDate + " " + this.spentTime,
          description: this.spentDescription,
          attachments: base64Files
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.addModalSpent = false
              Toast.success('Dépense enregistrée avec succès')
              this.refreshCategoryList()
              this.refreshSpentCategoryList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSpent = false
          })
      },
      multiplyWithSurface(element) {
        return Math.ceil(element * this.surfaceParcel)
      },
      validProduction() {
        return this.productionName.trim().length >= 3
          && this.productionStartDay != ""
          && this.productionStartTime != ""
          && this.parcelSelected != null
      },
      validActivity() {
        if (!Regex.isPositiveNumber(this.activityDuration))
          return false
        if (!Regex.isPositiveNumber(this.activityCost))
          return false
        for (let i = 0; i < this.activityInputs.length; i++) {
          if (!Regex.isPositiveNumber(this.activityInputs[i].quantity))
            return false
        }
        for (let i = 0; i < this.activityEquipments.length; i++) {
          if (!Regex.isPositiveNumber(this.activityEquipments[i].quantity))
            return false
        }
        return true
      },
      validSpent() {
        return this.spentName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.spentCost)
          && (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3)
          && (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3)
          && (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3)
      },
      addSpentCategory() {
        this.spentCategoryNewDisplay = true
      },
      addSpentEmitter() {
        this.spentEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.spentReceiverNewDisplay = true
      },
      refreshCategoryList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentsMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshSpentCategoryList() {
        
        Api.get('/exploitation/spent-category/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentCategories = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
    }
  }
</script>

<style>
  .tree-node{
    display: inherit !important;
  }
</style>
<style scoped>
  .autocomplete{
    width: 100%;
    border-radius: 0;
  }
  
  .num-badge{
    width: 15px;
    height: 15px;
    margin-left: -3px;
    text-align: center;
    padding: auto 0;
  }
  
  .unit{
    width: 50px;
  }
  
  default-unit{
    font-size: 20px;
    font-family: Candara;
    font-weight: bold;
    text-decoration: underline;
  }
  
  text-recommended{
    font-style: italic;
    margin-top: 10px;
    font-weight: 600;
    color: #009000;
  }
  
  text-recommended::after,
  text-recommended::before{
    content: " \2022 ";
  }
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }
</style>
